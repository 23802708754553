@import '../../variables';

div#header.container-fluid {

  // position: fixed;
  // z-index: 9;
  .row {
    .col-2 {
      .logo {
        img.img-fluid {
          display: none;
        }

        img.logo-white {
          display: block;
        }
      }
    }

    .col-7 {
      .center-menu {
        ul.nav {
          //height: 50px;
          align-items: center;

          li.nav-item.btn-book {
            padding-left: 50px;
            padding-right: 15px;
          }



          li {

            a.nav-link {
              color: $main;

            }

          }

          li.lang {
            .label {
              color: #aaa;


            }
          }
        }
      }
    }

    .col-3 {
      .right-menu ul.nav li {
        a.nav-link {
          color: $main;
        }

        // &.nav-item.btn-book::before {
        //   color: $main;
        // }
      }

    }
  }

  // &.fixed {
  //   .row .col-7 .center-menu ul.nav li a.nav-link {
  //     color: $main;
  //   }

  //   .col-3 .right-menu ul.nav {

  //     li a.nav-link,
  //     li#bar-mobile a {
  //       color: $main;
  //     }
  //   }

  // }
}

section.slide {
  .carousel-item {
    .caption {
      // position: absolute;
      // top: 30%;
      // left: 10%;

      .sub-title {
        // border-bottom: 1px solid #ffffff;
        display: inline-block;
        // margin-bottom: 20px;

        h6 {
          color: white;
          font-size: 36px;
        }
      }

      h3.title {
        color: white !important;
        position: relative;
        text-transform: uppercase;
        left: 10%;
        font-size: 70px;
        font-weight: 600;
        // width: 70%;
        // padding-bottom: 30px;
        opacity: 0;
        visibility: hidden;
        text-shadow: 0 0 3px #0d0d0d3d, 0 0 5px #12121275;
        -webkit-transition: .3s ease-in-out;
        transition: .3s ease-in-out;
      }

      .btn-book a {
        font-family: 'chipmong-kh-bold', bold-chipmong, sans-serif;
        display: inline-block;
        color: white;
        background-color: #CD901B;
        font-size: 13px;
        font-weight: 700;
        padding: 5px 35px;
        text-decoration: none;
        position: relative;
        z-index: 2;
      }
    }
  }

  .carousel-indicators {
    z-index: 2;
  }

  .carousel-item.active {
    .caption {
      h3.title {
        opacity: 1;
        visibility: visible;
        left: 0;

      }
    }
  }

  .carousel-indicators li {
    width: 13px;
    height: 13px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;

    &.active {
      background-color: #003664;
    }
  }

  .btn-smooth {
    position: absolute;
    width: 100%;
    bottom: 0;
    z-index: 99;

    a img {
      width: 26px;
    }

  }

}

section#des-about {
  .image {
    padding-right: 10%;
  }

  .des-content {
    padding-left: 10%;

    h6.sub-title {
      font-weight: 700;
      color: $text-gray;
    }

    h1.title {
      color: $main;
      font-weight: 700;
      font-size: 50px;
      width: 54%;
      position: relative;
    }
  }
}

section.news,
section#sec-4 {
  background-color: $gray;

  .view-more {
    height: 70px;
  }
}

p.label-welcome {
  font-size: 32px;
  font-family: 'chipmong-kh-bold', 'chipmong';
}

#header .col-3 .right-menu ul.nav li.nav-item.btn-book a {
  font-size: 12px;
}

.news h1.title,
.score-card h1.title,
#sec-4 h1.title {
  text-align: center;
  color: #114676;
}

section#sec-4 .slide {
  padding: 20px;
}

span.view i {
  padding-right: 10px;
}

// 28 04 2023 ========================================================================
section.slide .carousel-item .caption {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 1;
}

.w-100 {
  width: 100% !important;
  object-fit: cover;
  margin: auto;
  max-height: 1100px;
  min-height: 550px;
}

.slide-description {
  color: white;
  font-size: 15px;
}

.slide .carousel-inner .view:before {
  content: "";
  position: absolute;
  background: rgb(0 0 0 / 26%);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

#header {
  background: white;
  position: fixed;
  z-index: 9;
  padding: 5px 0;
  box-shadow: 2px 2px 4px #00000024;
}

.logo img {
  width: 45%;
}

section.slide .carousel-item .caption .sub-title h6 {
  text-shadow: 2px 2px 4px #000000;
}

section.slide .carousel-item .caption h3.title {
  text-shadow: 2px 2px 4px #000000;
}

div#header.container-fluid .row .col-3 .right-menu ul.nav li a.nav-link {
  color: #CD901B;
  // padding: 5px 20px;
}

li.nav-item:last-child a {
  padding-right: 0 !important;
}

li.nav-item.btn-book a {
  font-family: "chipmong-kh-bold", chipmong-bold, sans-serif !important;
}

ul.dropdown-menu.show li a {
  font-size: 13px;
}

li.nav-item.active a {
  font-family: "chipmong-kh-bold", chipmong-bold, sans-serif !important;
}

.scroller {
  width: 3px;
  height: 10px;
  margin-top: 0px;
  background-color: #FFFFFF;
  border-radius: 50px;
  animation-name: scroll;
  animation-duration: 2.2s;
  animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
  animation-iteration-count: infinite;
  display: inline-block;
}

.mousey {
  border-color: #FFFFFF;
  border-width: 3px;
  border-radius: 50px;
  border-style: solid;
  box-sizing: border-box;
  display: inline-block;
  text-align: center;
  padding: 7px 10px;
}

.blog .image img {
  height: 318px;
  object-fit: cover;
}

@media only screen and (min-width: 1400px) {
  .w-100 {
    max-height: 980px;
  }
}

@media only screen and (max-width: 1400px) {
  #header .col-7 .center-menu ul.nav li a.nav-link {
    padding-right: 0 !important;
  }
}

.slider-widget-image {
  width: calc(712px * 0.7275 + 712px * 0.7275 * 0.5 * 0.84);
  position: relative;
  // overflow: hidden;
}

.slider-3d-wraper {
  position: relative;
  width: 100%;
  height: 500px;
  max-width: 1024px;
}

.hotram-slider-3d-left_to_right .slider-widget-image {
  // width: calc(712px * 0.7275 + 712px * 0.7275 * 0.5 * 0.84);
  position: relative;
  width: 100%;
  // overflow: hidden;
}

.hotram-slider-3d-left_to_right .slider-widget-image .slider-3d-wraper .slider3d_center_R {
  // left: calc(712px * 0.7275 * 0.84 * 0.5);
  // width: calc(712px * 0.7275);
  width: 100%;
  left: 0;
  height: 90%;
  top: 10%;
}

.hotram-slider-3d-left_to_right .slider-widget-image .slider-3d-wraper .slider3d_L1_R {
  top: 0;
  left: 20%;
  // width: calc(712px * 0.7275 * 0.84);
  width: 90%;
  height: 84%;
}

.hotram-slider-3d-left_to_right .slider-widget-image .slider-3d-wraper .slider3d_L2_R {
  top: -10%;
  left: 10%;
  width: 80%;
  height: 84%;
}

.slider3d_L2_R {
  position: absolute;
  top: 15%;
  left: 0%;
  width: 40%;
  height: 70%;
  background-color: rgb(175 175 175 / 15%);
  z-index: 0;
}

.slider3d_center_R {
  position: absolute;
  top: 0%;
  left: 40%;
  width: 60%;
  height: 100%;
  background-color: rgb(175 175 175 / 15%);
  z-index: 3;
}

.slider3d_L1_R {
  position: absolute;
  top: 15%;
  left: 0%;
  width: 40%;
  height: 70%;
  background-color: rgba(0, 0, 50, 0.7);
  z-index: 2;
}

.slider3d_R1_R {
  position: absolute;
  top: 15%;
  left: 0%;
  width: 40%;
  height: 70%;
  background-color: rgba(0, 0, 50, 0.7);
  z-index: 0;
}

ul.slider3d-dots {
  position: absolute;
  padding-left: 0;
  right: 50%;
  left: 50%;
  z-index: 3;
  display: flex;
  gap: 15px;
}

ul.slider3d-arrows {
  position: absolute;
  bottom: 0;
  right: 1px;
  padding-left: 0;
  list-style: none;
  display: flex;
  z-index: 4;
  gap: 1px;
  left: 0;
  margin: 0;
}

ul.slider3d-dots li {
  color: #D9D9D9;
  width: 6px;
  height: 6px;
  // opacity: 0.5;
}

ul.slider3d-dots li.slider3d-dot-active {
  opacity: 1;
  color: #CD901B;
}

.slider3d-arrow.slider3d-arrow-next::after {
  background-color: #d7b36a;
  opacity: 1;
}

// ul.slider3d-arrows {
//   position: absolute;
//   bottom: 0;
//   right: 1px;
//   padding-left: 0;
//   list-style: none;
//   display: flex;
//   z-index: 2;
//   gap: 1px;
//   margin: 0;
// }
.slider3d-arrow {
  color: transparent;
  font-size: 0;
  opacity: 1 !important;
  padding: 10px 14px;
  background: #d7b36a;
}

.slider3d-arrow::after {
  font-family: "Font Awesome 5 Pro";
  background-color: #d7b36a;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}

li.slider3d-arrow.slider3d-arrow-prev:after {
  content: "\F053";
}

li.slider3d-arrow.slider3d-arrow-next:after {
  content: "\F054";
}

.hotram_slider3d {
  cursor: pointer;
  -webkit-transition: width 1s, height 1s, top 1s, left 1s;
  -moz-transition: width 1s, height 1s, top 1s, left 1s;
  -o-transition: width 1s, height 1s, top 1s, left 1s;
  -ms-transition: width 1s, height 1s, top 1s, left 1s;
  transition: width 1s, height 1s, top 1s, left 1s;
  cursor: grab;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top-center;
}

.elementor-widget:not(:last-child) {
  width: 100%;
  margin-bottom: 20px;
}

.elementor-widget-wrap {
  padding: 0px 0px 0px 15%;
  position: relative;
  width: 100%;
  flex-wrap: wrap;
  align-content: flex-start;
  display: flex;
  align-content: center;
  align-items: center;
}

.gc-course-overview .elementor-widget-container {
  max-width: 650px;
}

.elementor-text-editor.elementor-clearfix p {
  text-align: left;
  color: #44443C;
  font-size: 16px;
  // line-height: 1.38em;
  // letter-spacing: 0.4px;
}

h2.elementor-heading-title.elementor-size-default {
  color: #114676;
  font-size: 45px;
  text-transform: uppercase;
  // letter-spacing: 0.02px;
}

.elementor-logo-wrap p,
.elementor-btn {
  padding: 10px 0px 0px 30px;
}

.elementor-btn {
  border-radius: 6px;
  font-size: 13px;
  padding: 8px;
  margin: 40px 0px;
}

.elementor-btn a {
  width: 120px;
  border: 2px solid #CD901B;
  border-radius: 6px;
  text-align: center;
  color: #CD901B;
  font-size: 13px;
  padding: 8px 15px;
  margin: 20px 0px;
  cursor: pointer;
}

.elementor-btn a {
  color: #CD901B;
}

.slick-slide img {
  width: 100%;
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

#sec-4 .slide img {
  border: 1px solid #00000029;
  border-radius: 6px;
}

#sec-4 .slick-arrow:first-child {
  margin-right: 67px;
}

#sec-4 .slick-arrow {
  position: absolute;
  right: 0;
  top: -38px;
  background: rgb(244 244 244);
  border: none;
  padding: 10px 14px;
  color: rgb(244 244 244);
}

button.slick-prev:after {
  content: "\F053";
  position: absolute;
  color: white;
  background: #ce8f1b9c;
  z-index: 1;
  padding: 10px 17px;
  font-family: "Font Awesome 5 Pro" !important;
  right: 0;
  top: 0;
}

button.slick-next:after {
  content: "\F054";
  position: absolute;
  color: white;
  background: #ce8f1b9c;
  z-index: 1;
  padding: 10px 17px;
  font-family: "Font Awesome 5 Pro" !important;
  top: 0;
  left: 0;
}

#sec-5 .elementor-partner,
#sec-5 h2.elementor-heading-title.elementor-size-default {
  text-align: center;
  color: white;
}

.elementor-join a {
  background: white;
  padding: 10px 20px;
  border-radius: 5px;
  font-family: 'chipmong-kh-bold', 'chipmong-bold';
  font-size: 13px;
  color: #003664;
}

.elementor-join {
  padding: 25px;
}

.breadcrumb-wrap .breadcrumb {
  padding: 0.75rem 0;
  height: auto !important;
  min-width: 350px;
    width: 100%;
}
#blog-show {
  overflow: hidden;
}
.blog-content .breadcrumb-wrap {
  margin-top: 2.5em;
}

.blog-content .breadcrumb-wrap .one-line {
  width: 40% !important;
}

.blog-content .title {
  font-size: 18px;
}

.img-fluid {
  max-width: 100%;
  height: auto;
  width: 100%;
}

.blog-content .content {
  padding: 15px 0 0;
}

.row.gallery-img img {
  padding: 10px;
}

// .related-article:before {
//   content: "";
//   position: absolute;
//   background: #BEBEBE;
//   width: 100%;
//   height: 2px;
//   bottom: 0;
//   top: 44px;
// }

// .related-article:after {
//   content: "";
//   position: absolute;
//   width: 20%;
//   background: #CE8F1A;
//   top: 37px;
//   z-index: 9;
//   height: 8px;
// }

.content-article .content {
  padding: 0 15px;
}

.related-article .content-article ul {
  padding: 0;
  list-style: none;
}

.related-article .content-article ul li img {
  width: 40%;
  object-fit: cover;
  border-radius: 6px;
}

.related-article .content-article ul li {
  display: inline-flex;
}

.related-article .content-article ul li {
  margin-bottom: 20px;
}

.related-article {
  overflow: hidden;
  position: relative;
}

.breadcrumb-wrap .breadcrumb .breadcrumb-item.active span,
.breadcrumb-wrap .breadcrumb .breadcrumb-item a {
  font-size: 12px;
}

.breadcrumb-wrap .breadcrumb .breadcrumb-item {
  font-size: 12px;
  font-weight: 700;
  font-family: "chipmong-kh-bold", chipmong-bold, sans-serif;
  text-transform: uppercase;
}

.content-article ul li:first-child:before {
  content: "";
  position: absolute;
  background: #f4f4f4;
  width: 100%;
  height: 2px;
  margin: -23px;
}

.content-article ul li:first-child:after {
  content: "";
  position: absolute;
  width: 25%;
  background: #CE8F1A;
  z-index: 9;
  height: 8px;
  margin: -31px;
}

.foodBeverage img {
  height: 338px;
  object-fit: cover;
}

.contact-us ul li {
  color: #000000;
}

input.form-control::placeholder {
  font-size: 13px;
}

textarea.form-control {
  height: auto;
  font-size: 13px;
}

button.btn-orange.shadows {
  font-family: 'chipmong-kh-bold', 'chipmong-bold';
  background: #CD901B;
  border: 0;
  padding: 7px 20px;
  font-size: 12px;
  border-radius: 6px;
  color: white;
}

.text-right {
  text-align: right !important;
  padding: 20px 15px;
}

.circle-bg:before {
  content: "";
  width: 174px;
  height: 174px;
  background: #d9d9d933;
  position: absolute;
  right: 35%;
  z-index: -1;
  border-radius: 120px;
  top: -20px;
}

#contact .header-title {
  text-align: center;
  padding: 30px;
  background: #9090902e;
  vertical-align: middle;
}

.sub-header img {
  width: 45%;
  position: relative;
  padding-top: 20px;
}


.grid-wrapper>div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.grid-wrapper>div>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.grid-wrapper {
  display: grid;
  grid-gap: 10px;
  // grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  // grid-template-columns: auto;
  grid-template-columns: repeat(3, minmax(250px, 1fr));
  grid-auto-rows: auto;
  grid-auto-flow: dense;
}

.grid-wrapper .wide {
  grid-column: span 2;
}

.grid-wrapper .tall {
  grid-row: span 2;
}

.grid-wrapper .big {
  grid-column: span 2;
  grid-row: span 2;
}
.booking-form {
  padding: 5%;
}
.form-control {
  height: calc(1.5em + 0.75rem + 8px);
  font-size: 15px !important;
}
.col-md-12.content img {
  min-height: 450px;
  object-fit: cover;
}
.button-book-stay {
  float: right;
  text-align: center;
  background: #000000;
  padding: 8px 25px;
  color: white;
  font-family: "chipmong-kh-bold", chipmong-bold, sans-serif;
  border-radius: 6px;
  font-size: 13px;
  cursor: pointer;
}
.button-book-stay a {
  color: white;
}
#booking .content-success .icon {
  border-radius: 50%;
  height: 80px;
  width: 80px;
  border: 3px solid #003664;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 0 auto;
}
#booking .content-success .icon i {
  color: #003664;
  font-size: 40px;
  animation-name: check;
  animation-duration: 1.2s;
  animation-iteration-count: infinite;
}
#booking .content-success {
  width: 70%;
  margin: 0 auto;
  text-align: center;
}
.content-article .content {
  width: 60%;
  float: right;
}
.btn-explore-more {
  position: absolute;
  margin-top: -8em;
  width: 80px;
  padding: 1px;
  border-radius: 3px;
  background: white;
  text-align: center;
  right: -14px;
  box-shadow: 0px 0px 9px #00000026;
}
div#btn-back-to-top:hover {
  color: white;
}
.btn-explore-more a {
  color: #0f4676;
}
// 28 04 2023 ========================================================================
@media only screen and (min-width: 1300px) {

  // .container {
  //   max-width: 1920px;
  //   width: 95%;
  // }
  .blog-content .title {
    font-size: 28px;
  }

  // .related-article:before {
  //   content: "";
  //   position: absolute;
  //   background: #BEBEBE;
  //   width: 100%;
  //   height: 2px;
  //   bottom: 0;
  //   top: 46px;
  // }
  // .related-article:after {
  //   content: "";
  //   position: absolute;
  //   width: 20%;
  //   background: #CE8F1A;
  //   top: 40px;
  //   z-index: 9;
  //   height: 8px;
  // }
}

@media only screen and (min-width: 1200px) {
  .slide-description {
    font-size: 15px;
    width: 50%;
    margin: auto;
  }

  section.slide .carousel-item .caption .sub-title h6 {
    color: white;
    font-size: 50px;
  }

  section.slide .carousel-item .caption h3.title {
    font-size: 65px;
  }

  #header .col-7 .center-menu ul.nav li a.nav-link {
    padding-right: 1.15rem;
    padding-left: 1.15rem;
  }
}

@media only screen and (max-width: 1240px) {
  #header .col-3 .right-menu ul.nav li#bar-mobile a {
    color: rgb(12, 12, 12);
  }

  section.slide .carousel-item .caption h3.title {
    font-size: 50px !important;
  }

  .sy-6 {
    padding-top: 10%;
    padding-bottom: 6%;
  }

  //   .related-article:before {
  //     content: "";
  //     position: absolute;
  //     background: #BEBEBE;
  //     width: 100%;
  //     height: 2px;
  //     bottom: 0;
  //     top: 51px;
  // }
  // .related-article:after {
  //   content: "";
  //   position: absolute;
  //   width: 20%;
  //   background: #CE8F1A;
  //   top: 45px;
  //   z-index: 9;
  //   height: 8px;
  // }
}

@media only screen and (max-width: 1024px) {
  section.slide {
    .carousel-item {
      .caption {
        .sub-title h6 {
          font-size: 26px;
        }

        h3.title {
          font-size: 50px;
        }

        .btn-book a {
          font-size: 16px;
        }
      }
    }

  }

  section#des-about {
    .image {
      padding-right: 0;
    }

    .des-content {
      padding-left: 0;

      h1.title {
        font-size: 40px;
        width: 100%;
      }

      .text-body {
        font-size: 14px;

        &.pt-5 {
          padding-top: 30px !important;
          ;
        }

        &.pb-4 {
          padding-bottom: 20px !important;
        }
      }
    }
  }

  section.news,
  section.score-card {
    .row.header-title.pb-5 {
      padding-bottom: 15px !important;
    }

    .view-more {
      height: 50px;
    }

  }

  section.slide .carousel-item .caption {
    width: 80%;
  }

  // .related-article:after {
  //   content: "";
  //   position: absolute;
  //   width: 20%;
  //   background: #CE8F1A;
  //   top: 43px;
  //   z-index: 9;
  //   height: 8px;
  // }

  // .related-article:before {
  //   content: "";
  //   position: absolute;
  //   background: #BEBEBE;
  //   width: 100%;
  //   height: 2px;
  //   bottom: 0;
  //   top: 50px;
  // }
}

@media only screen and (max-width: 992px) {
  section.slide {
    .carousel-item {
      .caption {
        .sub-title {
          margin-bottom: 0;
        }

        // h3.title{
        //   padding-bottom: 15px;
        // }
      }
    }
  }

  .grid-wrapper {
    grid-template-columns: auto;
  }
}

@media only screen and (max-width: 768px) {
  section.slide {
    .carousel-item {
      .caption {
        .sub-title h6 {
          font-size: 22px;
        }

        h3.title {
          font-size: 28px;
        }

        .btn-book a {
          font-size: 14px;
        }
      }
    }
  }

  section#des-about {

    .des-content {
      h1.title {
        font-size: 34px;

      }
    }
  }

  .hotram-slider-3d-left_to_right .slider-widget-image .slider-3d-wraper .slider3d_L1_R {
    top: 0;
    left: 20%;
    width: 80%;
    height: 80%;
  }

  .hotram-slider-3d-left_to_right .slider-widget-image .slider-3d-wraper .slider3d_L2_R {
    top: -10%;
    left: 10%;
    width: 70%;
    height: 80%;
  }

  .hotram-slider-3d-left_to_right .slider-widget-image .slider-3d-wraper .slider3d_center_R {
    width: 90%;
    left: 0;
    height: 90%;
    top: 10%;
  }

  .elementor-column {
    position: relative;
    min-height: 1px;
    padding: 40px 0 0px 0px;
  }

  .mousey {
    padding: 5px 8px;
  }

  .elementor-widget-wrap {
    padding: 40px 0px 0px 0;
  }

  h2.elementor-heading-title.elementor-size-default {
    font-size: 32px;
  }

  .elementor-logo-wrap {
    display: inline-block;
    text-align: center;
  }

  .elementor-logo-wrap p {
    padding: 10px 0px 0px 0px;
  }

  .sy-6 {
    padding-top: 10%;
    padding-bottom: 6%;
  }

  p.label-welcome {
    font-size: 28px;
    font-family: "chipmong-kh-bold", "chipmong";
  }

  #des-about {
    background-size: 50% !important;
  }

  .sy-5 {
    padding-top: 5%;
  }
}

@media only screen and (max-width: 668px) {
  #des-about {
    .col-6 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .col-6.d-none {
      display: block !important;
      padding-bottom: 30px;
    }

    .col-6.center {
      display: none;
    }

    .image {
      width: 75%;
      margin: 0 auto;
    }
  }
}

@media only screen and (max-width:576px) {
  section.slide .carousel-item .caption h3.title {
    font-size: 32px !important;
  }
  #sec-4 .slick-arrow:first-child {
    margin-right: 50px;
  }
  #sec-4 .slick-arrow {
    position: absolute;
    right: 0;
    top: -38px;
    background: rgb(244, 244, 244);
    border: none;
    padding: 10px 3px;
    color: rgb(244, 244, 244);
  }
  section#sec-4 .slide {
    padding: 20px;
    padding-right: 0;
    padding-left: 0;
  }
  .elementor-partner {
    font-size: 12px;
  }
  .elementor-join a {
    font-size: 8px;
  }
  h2.elementor-heading-title.elementor-size-default {
    font-size: 22px;
  }
}

@media only screen and (max-width:480px) {
  #des-about .image {
    width: 100%;
  }
}

@media only screen and (max-width:376px) {
  // section.slide .carousel-item .caption {
  //   h3.title{
  //     font-size: 20px;
  //   }
  // }
}